import React from "react"
import Breadcrumbs from "../../components/common/breadcrumbs";
import SEO from "../../components/common/seo";
import Testimonials from "../../components/testimonials/testimonials";
import LayoutSecondary from "../../components/common/layout-secondary";
import { graphql } from 'gatsby'

const breadcrumbs = [
    {
        text: 'Talento',
        slug: '../',
    },
    {
        text: 'Estudiantes',
        active: true,
    },
]

const TecalisStudentsPage = ({data, pageContext}) => {

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO title="Estudiantes - Tecalis"
                 description={"Formación y trabajo van de la mano. Queremos ayudar y contribuir a la formación práctica de estudiantes apasionados y motivados por la tecnología."}/>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            {/*<TitleStudents title={data.studentsPage.frontmatter.title}/>*/}
            {/*<GalleryMosaic/>*/}
            {/*<TextIntro data={data.textIntro}/>*/}

            {/*<Programs data={data.programs}/>*/}
            {/*<TimelineDay data={data.timelineDay}/>*/}

            {/** WELCOME STUDENTS
             ================================================== */}
            <section className="py-8 py-md-11 mb-9 bg-white">
                <div className="container">

                    {/** Heading */}
                    <h1 align="center" className="display-4 font-weight-bold spacing title-feed-2">
                        Estudiantes
                    </h1>

                </div>
                {/** / .container */}
            </section>

            {/** GALLERY STUDENTS
             ================================================== */}
            <section className="gallery-students bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            {/** Image */}
                            <div className="form-row">
                                <div className="col-4">

                                    <img src={require("../../images/tecalis-students_01.png").default} className="img-fluid"
                                         alt="..."/>
                                </div>
                                <div className="col-3">

                                    <img src={require("../../images/tecalis-students_02.png").default}
                                         className="img-fluid mb-4"
                                         alt="..."/>
                                    <img src={require("../../images/tecalis-students_03.png").default}
                                         className="img-fluid mt-1"
                                         alt="..."/>

                                </div>
                                <div className="col-5">

                                    <div className="form-row mb-4">
                                        <div className="col-5">
                                            <img src={require("../../images/tecalis-students_04.png").default}
                                                 className="img-fluid" alt="..."/>

                                        </div>
                                        <div className="col-7">
                                            <img src={require("../../images/tecalis-students_05.png").default}
                                                 className="img-fluid" alt="..."/>
                                        </div>
                                    </div>
                                    {/** / .row */}

                                    <img src={require("../../images/tecalis-students_06.png").default}
                                         className="img-fluid mt-1"
                                         alt="..."/>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>

            {/** TCL STUDENTS INTRO
             ================================================== */}
            <section className="bg-grey position-relative py-8 py-md-11 mb-9">

                {/** Content */}
                <div className="container">
                    <div className="row">

                        <div className="col-md-8 offset-md-2" data-aos="fade-up">

                            {/** Heading */}
                            <h2 className="display-4 font-weight-bold spacing">
                                TCL Students
                                <span className="bar-tecalis"/>
                            </h2>

                            {/** Text */}
                            <p className="font-size-lg text-black-80 mb-6 spacing">
                                <b>Bienvenidos estudiantes!</b> En Tecalis le damos la bienvenida a los y las
                                estudiantes en
                                todas nuestras oficinas. Desde el primer día, trabajarás con nosotros en diversos
                                proyectos. Tecalis impulsa la igualdad de oportunidades para todos y todas, sea cual sea
                                tu
                                experiencia. Valoramos algo más que eso. Somos únicos, y buscamos personas únicas.
                                Apostamos por un equipo multidisciplinar para mejorar nuestro trabajo, conocer
                                diferentes puntos de vista, y aprender los unos de los otros. Nuestra pasión, la
                                innovación tecnológica. Nuestra cultura empresarial, empoderar al equipo. </p>
                        </div>

                    </div>
                </div>
                {/** / .row */}
            </section>
            {/** / .container */}

            <Testimonials heading={data.testimonials.frontmatter.heading}/>

            {/** PROGRAMAS Y BECAS
             ================================================== */}
            <section className="bg-grey position-relative pt-8 pb-9 pt-md-11 pb-md-10">

                <div className="container">

                    {/** Heading */}
                    <h2 align="center" className="display-4 font-weight-bold spacing">
                        Nuestros programas
                        <div align="center"><span className="bar-tecalis"/></div>
                        <br/>
                    </h2>

                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 programas">

                            {/** Card */}
                            <div className="card mb-6 mb-md-0 lift">
                                <div className="card-body">

                                    {/** Heading */}
                                    <br/>
                                    <h2 align="center" className="font-weight-bold spacing">
                                        TCL Despega
                                    </h2>

                                    <br/>
                                    <div align="center">
                                        <a href="/#" data-toggle="modal" data-target="#despega"
                                           className="btn btn-primary spacing">Más info <i
                                            className="fe fe-arrow-right d-none d-md-inline ml-3"/></a>
                                    </div>
                                    <br/>

                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 programas">

                            {/** Card */}
                            <div className="card mb-6 mb-md-0 lift">
                                <div className="card-body">

                                    {/** Heading */}
                                    <br/>
                                    <h2 align="center" className="font-weight-bold spacing">
                                        TCL Demodays
                                    </h2>

                                    <br/>
                                    <div align="center">
                                        <a href="/#" data-toggle="modal" data-target="#demodays"
                                           className="btn btn-primary spacing">Más info <i
                                            className="fe fe-arrow-right d-none d-md-inline ml-3"/></a>
                                    </div>
                                    <br/>

                                </div>
                            </div>
                        </div>

                    </div>
                    {/** / .row */}
                </div>
                {/** / .container */}
            </section>

            {/** UN DIA INNOVANDO
             ================================================== */}
            <section className="pt-8 pb-9 pt-md-11 pb-md-13 bg-white">

                <div className="container">

                    {/** Heading */}
                    <h2 align="center" className="display-4 font-weight-bold spacing">
                        Un día innovando
                        <div align="center"><span className="bar-tecalis"/></div>
                    </h2>

                    {/** Text */}
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <h3 className="font-size-lg text-black-80 mb-6 font-weight-bold spacing">
                                En Tecalis el horario laboral lo eliges tú, la cultura de trabajo innovadora la ponemos
                                nosotros
                                … Así es una jornada de un día innovando en nuestras instalaciones:
                            </h3>
                        </div>
                    </div>
                    {/** / .row */}

                    <div id="cd-timeline" className="cd-container">

                        <div className="cd-timeline-block">
                            <div className="cd-timeline-img cd-tecalis"/>
                            {/** circle tecalis */}
                            <div className="cd-timeline-content" data-aos="fade-right">
                                <h2 className="spacing font-weight-bold"><i className="fe fe-watch"/> Desayuno</h2>
                                <p className="spacing">Nada mejor que un buen café, infusión o zumo de nuestra área de
                                    refrigerio saludable para comenzar el día con buen pie.</p>
                            </div>
                            {/** cd-timeline-content */}
                        </div>
                        {/** cd-timeline-block */}

                        <div className="cd-timeline-block">
                            <div className="cd-timeline-img cd-tecalis"/>
                            {/** circle tecalis */}
                            <div className="cd-timeline-content" data-aos="fade-left">
                                <h2 className="spacing font-weight-bold"><i className="fe fe-watch"/> Reunión / Stand
                                    Up de
                                    la compañía</h2>
                                <p className="spacing">Nos reunimos con nuestro equipo para compartir lo que se trabajó
                                    el día
                                    anterior, analizar los errores/ problemas con los que nos hemos encontrado, y
                                    conocer cúal
                                    es la prioridad para hoy.
                                </p>
                            </div>
                            {/** cd-timeline-content */}
                        </div>
                        {/** cd-timeline-block */}

                        <div className="cd-timeline-block">
                            <div className="cd-timeline-img cd-tecalis"/>
                            {/** circle tecalis */}
                            <div className="cd-timeline-content" data-aos="fade-right">
                                <h2 className="spacing font-weight-bold"><i className="fe fe-watch"/> Descanso a
                                    media mañana
                                </h2>
                                <p className="spacing">Un poco de Ping pong, canasta o bici estática para desconectar,
                                    no está
                                    nada mal.</p>
                            </div>
                            {/** cd-timeline-content */}
                        </div>
                        {/** cd-timeline-block */}

                        <div className="cd-timeline-block">
                            <div className="cd-timeline-img cd-tecalis"/>
                            {/** circle tecalis */}
                            <div className="cd-timeline-content" data-aos="fade-left">
                                <h2 className="spacing font-weight-bold"><i className="fe fe-watch"/> Almuerzo</h2>
                                <p className="spacing">El cuerpo ya nos pide ingerir alimentos, y qué mejor que hacerlo
                                    en
                                    nuestro comedor equipado para poder comer tranquilos y distendidos.</p>
                            </div>
                            {/** cd-timeline-content */}
                        </div>
                        {/** cd-timeline-block */}

                        <div className="cd-timeline-block">
                            <div className="cd-timeline-img cd-tecalis"/>
                            {/** circle tecalis */}
                            <div className="cd-timeline-content" data-aos="fade-right">
                                <h2 className="spacing font-weight-bold"><i className="fe fe-watch"/> Sesión
                                    energizante para
                                    afrontar la jornada de tarde</h2>
                                <p className="spacing">Sabemos que después de comer, es posible que nos entre un poco de
                                    pereza.
                                    Nada mejor que tomarnos una bebida en nuestra sala de descanso para reponer las
                                    pilas.</p>
                            </div>
                            {/** cd-timeline-content */}
                        </div>
                        {/** cd-timeline-block */}

                        <div className="cd-timeline-block">
                            <div className="cd-timeline-img cd-tecalis"/>
                            {/** circle tecalis */}
                            <div className="cd-timeline-content" data-aos="fade-left">
                                <h2 className="spacing font-weight-bold"><i className="fe fe-watch"/> ¡Vámonos a
                                    casa!</h2>
                                <p className="spacing">Se acabó nuestra jornada por hoy. A descansar, ¡que lo tenemos
                                    más que
                                    merecido!</p>
                            </div>
                            {/** cd-timeline-content */}
                        </div>
                        {/** cd-timeline-block */}

                    </div>
                </div>
            </section>

            {/** DEMODAYS */}
            <div className="modal fade" id="demodays" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        {/** Close */}
                        <button type="button" className="modal-close close text-white" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true" className="color-tecalis">×</span>
                        </button>
                        <div className="modal-body">
                            <h2 className="mb-0 font-weight-bold text-center spacing" id="">
                                TCL Demodays
                            </h2><br/><br/>
                            <ul className="list-unstyled mb-5">

                                <li className="d-flex">

                                    {/** Check */}
                                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                                        <i className="fe fe-check"/>
                                    </div>

                                    {/** Text */}
                                    <p className="spacing">
                                        ¿Quieres conocer cómo trabajamos? ¡Eso está hecho! Te invitamos a pasar un día
                                        con
                                        nosotros para que conozcas nuestras oficinas, cómo es nuestro día a día y
                                        compruebes que
                                        somos diferentes. Nos da igual si aún no has acabado la carrera, si estás en
                                        bachiller,
                                        o en la E.S.O. Lo que queremos es que disfrutes de una jornada con nosotros y te
                                        impregnes de la cultura Tecalis.
                                    </p>
                                </li>
                            </ul>

                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", zIndex: 1, top: 300, right: 15}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** DESPEGA */}
            <div className="modal fade" id="despega" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        {/** Close */}
                        <button type="button" className="modal-close close text-white" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true" className="color-tecalis">×</span>
                        </button>
                        <div className="modal-body">
                            <h2 className="mb-0 font-weight-bold text-center spacing" id="">
                                TCL Despega
                            </h2><br/><br/>

                            <ul className="list-unstyled mb-5">
                                <li className="d-flex">

                                    {/** Check */}
                                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                                        <i className="fe fe-check"/>
                                    </div>

                                    {/** Text */}
                                    <p className="spacing">
                                        <b>TCL Prácticas</b>: anímate a realizar tus prácticas de Universidad o
                                        Formación
                                        Profesional con nosotros. Participarás en proyectos reales, trabajarás para
                                        grandes
                                        empresas y contarás con unos compañeros y compañeras que te ayudarán en todo lo
                                        posible,
                                        haciéndote
                                        sentir uno más desde el primer momento.
                                    </p>
                                </li>
                                <li className="d-flex">

                                    {/** Check */}
                                    <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                                        <i className="fe fe-check"/>
                                    </div>

                                    {/** Text */}
                                    <p className="spacing">
                                        <b>TCL First Experience</b>: te ofrecemos una excelente oportunidad para iniciar
                                        tu
                                        carrera en el mercado laboral. Si acabas de graduarte y tienes ganas de
                                        desarrollar todo
                                        tu potencial y crecer profesionalmente, Tecalis es tu empresa.
                                    </p>
                                </li>
                            </ul>

                            {/** Decoration */}
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8"
                                 style={{position: "absolute", zIndex: 1, top: 370, right: 15}}>
                                <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                    <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                    <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </LayoutSecondary>
    )
}

export default TecalisStudentsPage

export const studentsPageQuery = graphql
    `query($lang: String) {
    studentsPage: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "people/tecalis-students/tecalis-students-page"
}
}) {
    frontmatter {
    title
}
}
    testimonials: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "people/tecalis-students/testimonials"
}
}) {
    frontmatter {
    heading
}
}
    textIntro: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "people/tecalis-students/text-intro"
}
}) {
    frontmatter {
    heading
}
    html
}
    programs: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "people/tecalis-students/programs"
}
}) {
    frontmatter {
    heading
    buttonText
    demodays{
    heading
    text
    link
}
    scholarships{
    heading
    text
    link
}
    dualtraining{
    heading
    text
    link
}
    other{
    heading
    text
    link
}
}
}
    timelineDay: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "people/tecalis-students/timeline-day"
}
}) {
    frontmatter {
    heading
    hour0930{
    heading
    text
}
    hour1000{
    heading
    text
}
    hour1030{
    heading
    text
}
    hour1400{
    heading
    text
}
    hour1530{
    heading
    text
}
    hour1700{
    heading
    text
}
}
}
    cookies: markdownRemark(
    frontmatter: {
    lang: {
    eq: $lang
}
    name: {
    eq: "cookies"
}
}) {
    frontmatter {
    cookiesStart
    cookiesEnd
    linkText
}
}
}`;
