import React from "react"

const Testimonial = ({testimonial}) => (

    <div align="center" className="w-100">
        {/** Ficha estudiante */}
        {/** Foto */}
        {/*<div align="center">*/}
        {/*    <br/>*/}
        {/*    <img src="https://via.placeholder.com/400x400/fff" width="100px" alt="..."*/}
        {/*         className="rounded-circle tech"/>*/}
        {/*    <br/><br/>*/}
        {/*</div>*/}
        {/** Nombre / Centro / Edad */}
        <h2 className="font-size-xl text-black-80 mb-6 font-weight-bold spacing"><span id="">{testimonial && testimonial.name}</span> &middot;
            <span id=""> {testimonial && testimonial.institution}</span> <span id=""/></h2>
        {/** Su experiencia en Tecalis */}
        <p className="font-size-lg text-black-80 mb-6 spacing">
            {testimonial && testimonial.testimonial}
        </p>
    </div>

)

export default Testimonial
