import React from "react"
// import styled from "styled-components";
import Testimonial from "./testimonial";
import {graphql, useStaticQuery} from "gatsby";
import Flickity from "react-flickity-component";

const Testimonials = ({heading}) => {

    const data = useStaticQuery(graphql`
      query {
        allStrapiTestimonial(sort: { fields: [id], order: [DESC] }) {
            edges {
                node {
                    testimonial
                    name
                    institution
                }
            }
        }
    }
    `)

    return (
        <section data-jarallax="" data-speed=".8" className="pt-8 pb-6 pt-md-10 pb-md-9 opiniones"
                 style={{background: "linear-gradient(rgba(255, 255, 255, 0.69),rgba(255, 255, 255, 0.69)), url('" + require("../../images/tecalis-students_07.png") + "') no-repeat center center fixed"}}>
            <div className="container">

                {/** Heading */}
                <h1 align="center" className="display-4 font-weight-bold spacing">
                    {heading}
                    <div align="center"><span className="bar-tecalis"/></div>
                </h1>

                <div className="row align-items-center">
                    <div className="col-md-8 offset-md-2">

                        {/** Slider */}
                        <Flickity
                            className={''} // default ''
                            elementType={'div'}
                            options={{
                                "wrapAround": true,
                                "prevNextButtons": false,
                                "pageDots": true,
                                "imagesLoaded": true,
                                "adaptiveHeight": false
                            }} // takes flickity options {}
                            disableImagesLoaded={false} // default false
                            reloadOnUpdate // default false
                            static // default false
                        >

                            {data.allStrapiTestimonial.edges.map((document, i) => (
                                <Testimonial testimonial={document.node} key={i}/>
                            ))}

                        </Flickity>
                        <br/><br/>

                    </div>
                </div>
                {/** / .row */}
            </div>
            {/** / .container */}
        </section>

    )
}

export default Testimonials
